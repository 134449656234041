<template>
  <div class="container">
    <div>
      <!-- <a-page-header
        style="border: 1px solid rgb(235, 237, 240)"
        title="患者历史操作记录"
        :breadcrumb="{ props: { routes } }"
      />-->
      <div class="query">
        <div class="search">
          <a-input placeholder="输入病案号或操作医生" type="text" v-model="patientnum" />
        </div>
        <div class="rangePicker">
          <a-range-picker show-time v-model="date" value-format="YYYY-MM-DD HH:mm:ss" />
        </div>
        <div class>
          <a-button type="primary" @click="search">搜索</a-button>
        </div>
        <div
          class="export"
          v-if="
            getJurisdictionList.indexOf('patientinoperation-downloadBtn') > -1
          "
        >
          <a-button type="primary" :loading="iconLoading" @click="derive">数据下载</a-button>
        </div>
      </div>

      <div v-if="getJurisdictionList.indexOf('patientinoperation-checkList') > -1">
        <ant-tabel
          v-if="show"
          :data="data"
          :columns="columns"
          :loading="loading"
          :status="status"
          :pagination="pagination"
          @changePaging="changePaging"
        ></ant-tabel>
      </div>
    </div>
  </div>
</template>
<script>
import excelUtil from "../../utils/dealwithExcelUtil";
import api from "@/api/index.js";
import storage from "../../storage/storage.js";
import moment from "moment";
import AntTabel from "../Ant-tabel/Ant-tabel.vue";
const columns = [
  {
    title: "病案号",
    dataIndex: "patient",
    key: "patient",
    width: 150,
    scopedSlots: {
      customRender: "patientin_number",
    },
  },
  {
    title: "操作医生",
    dataIndex: "user",
    key: "user",
    width: 150,
    scopedSlots: {
      customRender: "patientin_realName",
    },
  },
  {
    title: "年龄",
    dataIndex: "age",
    key: "age",
    scopedSlots: {
      customRender: "patientin_age",
    },
    width: 150,
  },
  {
    title: "本次血糖",
    dataIndex: "gls",
    key: "gls",
    width: 150,
  },
  {
    title: "目前RI速度",
    dataIndex: "insulin_speed",
    key: "insulin_speed",
    width: 200,
  },
  {
    title: "目前糖输入速度",
    dataIndex: "GIR",
    key: "GIR",
    width: 150,
  },
  {
    title: "两次间隔时间",
    dataIndex: "time_diff",
    key: "time_diff",
    width: 150,
  },
  {
    title: "采血部位",
    dataIndex: "blood_collection",
    key: "blood_collection",
    width: 150,
  },
  {
    title: "监测方法",
    dataIndex: "monitor_method",
    key: "monitor_method",
    width: 150,
  },
  {
    title: "建议RI速度(u/h)",
    dataIndex: "advise_insulin_speed",
    key: "advise_insulin_speed",
    width: 150,
  },
  {
    title: "实际RI速度(u/h)",
    dataIndex: "proposal_advise_insulin_speed",
    key: "proposal_advise_insulin_speed",
    width: 150,
  },
  {
    title: "建议静脉推注",
    dataIndex: "iv",
    key: "iv",
    width: 150,
  },
  {
    title: "实际静脉推注",
    dataIndex: "proposal_iv",
    key: "proposal_iv",
    width: 150,
  },
  // {
  //   title: "EN",
  //   dataIndex: "nutrition_en",
  //   key: "nutrition_en",
  // },
  // {
  //   title: "PN氨基酸",
  //   dataIndex: "nutrition_pn_amino_acid",
  //   key: "nutrition_pn_amino_acid",
  // },
  // {
  //   title: "PN脂肪乳剂",
  //   dataIndex: "nutrition_pn_intralipid",
  //   key: "nutrition_pn_intralipid",
  // },
  // {
  //   title: "多腔袋全合一",
  //   dataIndex: "multi_cavity_bag",
  //   key: "multi_cavity_bag",
  // },
  // {
  //   title: "PN碳水化合物",
  //   dataIndex: "nutrition_pn_carbohydrate",
  //   key: "nutrition_pn_carbohydrate",
  // },

  {
    title: "建议糖输入速度",
    dataIndex: "input_sugar_ml",
    key: "input_sugar_ml",
    width: 150,
  },
  {
    title: "实际糖输入速度",
    dataIndex: "proposal_input_sugar_ml",
    key: "proposal_input_sugar_ml",
    width: 150,
  },
  {
    title: "建议糖输入(g)",
    dataIndex: "input_sugar",
    key: "input_sugar",
    width: 150,
  },
  {
    title: "实际糖输入(g)",
    dataIndex: "proposal_input_sugar",
    key: "proposal_input_sugar",
    width: 150,
  },
  {
    title: "建议间隔时间",
    dataIndex: "period",
    key: "period",
    width: 150,
  },
  {
    title: "实际间隔时间",
    dataIndex: "proposal_period",
    key: "proposal_period",
    width: 150,
  },
  {
    title: "是否采纳",
    dataIndex: "proposal_is_adopt",
    key: "proposal_is_adopt",
    width: 150,
  },

  {
    title: "修改时间",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 150,
  },
  {
    title: "医院信息",
    dataIndex: "hospitalName",
    key: "hospitalName",
    width: 150,
  },
  {
    title: "备注",
    dataIndex: "tip",
    key: "tip",
    width: 150,
  },
  {
    title: "版本：最新/历史",
    dataIndex: "proposal_edtion",
    key: "proposal_edtion",
    width: 150,
  },
];

const data = [];
export default {
  components: {
    AntTabel,
  },
  data() {
    return {
      isSkip: false,
      timer: null,
      iconLoading: false,
      flag: 1,
      data,
      show: false,
      columns,
      loading: true,
      patientnum: "",
      date: null,
      getJurisdictionList: "",
      status: false,
      mergeData: [],
      routes: [
        {
          path: "index",
          breadcrumbName: "Patient-Operation Record",
        },
      ],
      pagination: {
        current: 1, //当前页
        pageSize: 10, //每页几条数据
        total: 10, //总数
      },
    };
  },
  created() {
    this.historyRecord();
  },
  mounted() {
    this.getJurisdiction();
  },

  methods: {
    getJurisdiction() {
      this.getJurisdictionList = storage.getItem("getJurisdiction");
    },
    historyRecord() {
      this.flag = 2;
      console.log(this.date);
      let obj = {
        name: this.patientnum,
        pagination: this.pagination,
        date: this.date,
        isSkip: this.isSkip,
      };
      api
        .historyRecord(obj)
        .then((res) => {
          this.data = [];
          this.pagination.total = res.count;
          res.data.forEach((data) => {
            if (!data.iv) {
              data.iv = 0;
            }
            if (!data.proposal_iv) {
              data.proposal_iv = 0;
            }
            if (!data.input_sugar) {
              data.input_sugar = 0;
            }
            if (!data.proposal_input_sugar) {
              data.proposal_input_sugar = 0;
            }
            if (!data.input_sugar_ml) {
              data.input_sugar_ml = "null";
            }
            if (!data.proposal_input_sugar_ml) {
              data.proposal_input_sugar_ml = "null";
            }
            if (data.hospital) {
              data.hospitalName = data.hospital.name;
            }
            data.createdAt = moment(data.createdAt).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            data.key = data.objectId;
            this.data.push(data);
            this.show = true;
          });
          this.data = this.data;
          this.loading = false;
        })
        .catch(() => { });
    },
    search() {
      this.isSkip = false;
      this.pagination.current = 1;
      this.historyRecord();
      this.loading = true;
    },
    //分页
    changePaging(page, pageSize) {
      this.isSkip = true;
      this.pagination.current = page;
      this.pagination.pageSize = pageSize;
      this.historyRecord();
    },
    //导出
    async derive() {
      console.log("下载");
      this.iconLoading = true;
      this.$store.commit("setStatus", true);
      let obj = {
        name: this.patientnum,
        date: this.date,
      };
      await api.downloadPatientHistory(obj).then((res) => {
        console.log(res);
        if (res) {
          this.getTask(res);
        } else {
          this.iconLoading = false;
          this.$store.commit("setStatus", false);
          this.$message.warning("下载失败");
        }
      });
    },

    //获取getTask
    getTask(data) {
      this.timer = setInterval(async () => {
        console.log(data);
        await api.getTask(data.objectId).then((res) => {
          console.log(res);
          let data = res.data[0];
          if (data.status == "success") {
            const a = document.createElement("a");
            a.href = data.result;
            a.download = "患者操作数据记录.xlsx";
            a.remove();
            a.click();
            console.log(a);
            this.iconLoading = false;
            this.$store.commit("setStatus", false);
            this.$message.success("下载成功");
            clearInterval(this.timer);
            this.timer = null;
          } else if (data.status == "executing") {
            this.iconLoading = true;
          } else if (data.status == "fail") {
            this.iconLoading = false;
            this.$store.commit("setStatus", false);
            this.$message.error("下载失败");
            clearInterval(this.timer);
            this.timer = null;
          }
        });
      }, 5000);
    },
  },
  watch: {},
};
</script>
<style scoped lang="less">
.query {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.search {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.search .ant-input {
  height: 32px;
  border-radius: 2px;
}

/deep/ .ant-calendar-picker-input{
  height: 32px;
  border-radius: 2px;
}

.ant-btn-primary {
  margin-left: 25px;
  height: 32px;
  border-radius: 2px;
  background: #2468f2;
}

.rangePicker {
  width: 400px;
}

.rangePicker /deep/ .ant-calendar-picker {
  width: 100% !important;
}

.export {
  flex: 1;
  text-align: right;
}

/deep/ .ant-table-body {
  max-height: calc(100vh - 265px) !important;
}
</style>
